<template>
  <div v-if="isshowgrid">
    <va-card title="ACL List">
      <template slot="actions">
        <va-button color="success"  icon="fa fa-plus" @click="add()">Add</va-button>
      </template>
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)">
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" v-if="props.rowData.video_status=='Finished'" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
          <va-button flat small color="red" icon="fa fa-remove" @click="deleteAcl(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
    <va-modal
      v-model="showRemoveModal"
      title="Remove ACL"
      size='small'
      :message="message"
      okText="Confirm"
      cancelText="Cancel"
      @ok="deleteAclContent(removed_row_content)"
      @cancel="cancel()">
    </va-modal>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card title="Add ACL">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <va-input
              v-model="acl_name"
              type="text"
              label="ACL Reference Name *"
              placeholder="Enter ACL Name"
              :error="!!aclNameErrors.length"
              :error-messages="aclNameErrors"
            />
            <va-input
              v-model="ip"
              type="text"
              label="IP *"
              pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
              placeholder="Enter IP (eg:1.3.2.1)"
              :error="!!ipErrors.length"
              :error-messages="ipErrors"
            />
            <va-select
              v-model="netmask"
              type="text"
              searchable
              :options="netmaskOptions"
              label="Subnet Mask *"
              placeholder="Select Netmask"
              :error="!!netMaskErrors.length"
              :error-messages="netMaskErrors"
              noClear
            />
            <div class="d-flex justify--center mt-3">
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createAcl()">Create</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'acl',
  beforeCreate () {
    this.$http.get(config.menu.host + 'acl').then(response => {
      this.acl_data = (response.body.length > 0) ? response.body : []
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllAcl()
    })
  },
  data () {
    return {
      errors: {
        logo: false,
        logonew: false,
      },
      acl_name: '',
      message: '',
      term: null,
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: true,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      unicastNetMaskErrors: [],
      unicastIPErrors: [],
      acl_data: [],
      aclNameErrors: [],
      ipErrors: [],
      netMaskErrors: [],
      ip: '',
      netmask: '',
      netmaskOptions: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32'],
    }
  },
  computed: {
    formReady () {
       return !this.aclNameErrors.length && !this.ipErrors.length && !this.netMaskErrors.length
    },
    fields () {
      return [{
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name:'acl_name',
	title:'ACL Reference Name',
      },
      {
        name: 'ip',
        title: 'IP',
      },
      {
        name: 'netmask',
        title: 'Netmask',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      var gridData = search(this.term, this.acl_data)
      var roleObject = {
        SYS_ADMIN: 'Sys Admin',
        SYSTEM_SUPPORT: 'System Support',
        SYSTEM_SALES: 'System Sales',
      }
      if (gridData.length > 0) {
        gridData.map(function (item) {
          for (var key in roleObject) {
            if (item.role === key) {
              item.role = roleObject[key]
            }
          }
        })
      }
      return gridData
    },
  },
  methods: {
    createAcl () {
      clearInterval(this.interval)
      this.aclNameErrors = this.acl_name ? [] : ['Acl name is required']
      this.ipErrors = this.ip ? [] : ['IP  is required']
      this.netMaskErrors = this.netmask ? [] : ['Netmask is required']
      
      if (!this.formReady) {
        return
      }

      var payload = {}
      payload.ip = this.ip
      payload.netmask = this.netmask
      payload.acl_name = this.acl_name
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'acl', payload).then(responseData => {
        if (responseData && responseData.body) {
          Vue.notify({
            text: responseData.body,
            type: 'success',
          })
        }
        loader.hide()
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          Vue.notify({
            text: errResponse.body,
            type: 'error',
          })
        }
        loader.hide()
      })
    },
    getAllAcl () {
      this.$http.get(config.menu.host + 'acl').then(response => {
        this.acl_data = (response.body.length > 0) ? response.body : []
        this.isshowgrid = true
      })
    },
    deleteAcl (rowData) {
      clearInterval(this.interval)
      this.message = 'Are you sure to delete ' + rowData.ip + ' Acl ?'
      this.showRemoveModal = true
      this.removed_row_content = rowData
    },
    deleteAclContent (rowData) {
      clearInterval(this.interval)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(config.menu.host + 'acl/' + rowData.acl_id).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({
            text: response.body,
            type: 'success',
          })
        }
        this.list()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({
            text: error.body,
            type: 'error',
          })
        }
      })
    },
    call_notification (message) {
      Vue.notify({
        text: message,
        type: 'error',
      })
    },
    add () {
      clearInterval(this.interval)
      this.ip = ''
      this.netmask = ''
      this.acl_name = ''
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    cancel () {
      clearInterval(this.interval)
      this.showRemoveModal = false
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllAcl()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style lang="scss">
body {
  .underline { text-decoration: underline; }
}
</style>
